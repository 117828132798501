/* 
数据键值命名转换
data: 要转换的数据 Object | Array
mode: 转换模式 0: 驼峰转下划线 | 1: 下划线转驼峰
 */
const transformKey = (data, mode = 0) => {
  if (!(data instanceof Array) && !(data instanceof Object)) {
    return data;
  }
  let newData = data instanceof Array ? [] : {};
  data = new Map(Object.entries(data));
  data.forEach((v, k) => {
    if (v && typeof v === 'object') v = transformKey(v, mode);
    if (mode) {
      k = k.replace(
        /([^_])(?:_+([^_]))/g,
        (_, p1, p2) => p1 + p2.toUpperCase()
      );
    } else {
      k = k.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);
    }
    newData[k] = v;
  });
  return newData;
};

// 解析URL 参数
const getUrlParam = name => {
  let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};

const forecastFilterForLotteryType = (
  forecastNumber = '',
  lotteryValue = ''
) => {
  return {
    1: {
      text: '定七'
    },
    10: {
      text: '直码'
    },
    11: {
      text: '定头',
      filter: () => !!lotteryValue && lotteryValue[0] === forecastNumber
    },
    12: {
      text: '定千',
      filter: () =>
        !!lotteryValue && lotteryValue.slice(1, 2) === forecastNumber
    },
    13: {
      text: '定百',
      filter: () =>
        !!lotteryValue && lotteryValue.slice(2, 3) === forecastNumber
    },
    14: {
      text: '定十',
      filter: () =>
        !!lotteryValue && lotteryValue.slice(-2, -1) === forecastNumber
    },
    15: {
      text: '定尾',
      filter: () => !!lotteryValue && lotteryValue.slice(-1) === forecastNumber
    },
    16: {
      text: '独胆',
      filter: () =>
        !!lotteryValue && lotteryValue.indexOf(forecastNumber) !== -1
    },
    17: {
      text: '2胆',
      filter: () =>
        !!lotteryValue && lotteryValue.indexOf(forecastNumber) !== -1
    },
    18: {
      text: '3胆',
      filter: () =>
        !!lotteryValue && lotteryValue.indexOf(forecastNumber) !== -1
    },
    19: {
      text: '4胆',
      filter: () =>
        !!lotteryValue && lotteryValue.indexOf(forecastNumber) !== -1
    },
    21: {
      text: '杀头',
      filter: () =>
        !!lotteryValue && forecastNumber.indexOf(lotteryValue[0]) === -1
    },
    22: {
      text: '杀千',
      filter: () =>
        !!lotteryValue &&
        forecastNumber.indexOf(lotteryValue.slice(1, 2)) === -1
    },
    23: {
      text: '杀百',
      filter: () =>
        !!lotteryValue &&
        forecastNumber.indexOf(lotteryValue.slice(2, 3)) === -1
    },
    24: {
      text: '杀十',
      filter: () =>
        !!lotteryValue &&
        forecastNumber.indexOf(lotteryValue.slice(-2, -1)) === -1
    },
    25: {
      text: '杀尾',
      filter: () =>
        !!lotteryValue && forecastNumber.indexOf(lotteryValue.slice(-1)) === -1
    },
    26: {
      text: '杀1码',
      filter: () => {
        return (
          !!lotteryValue &&
          lotteryValue.split('').every(e => {
            return forecastNumber.indexOf(e) === -1;
          })
        );
      }
    },
    31: {
      text: '复式7',
      filter: () =>
        !!lotteryValue && lotteryValue.indexOf(forecastNumber) !== -1
    }
  };
};

const getForecastText = type => {
  let typeData = forecastFilterForLotteryType()[type];
  return typeData ? typeData.text : '直码';
};

const getForecastNumberJudge = (
  forecastNumber = '',
  lotteryValue = '',
  type = ''
) => {
  if (!lotteryValue) return false;
  let typeData = forecastFilterForLotteryType(forecastNumber, lotteryValue)[
    type
  ];
  return typeData ? typeData.filter() : false;
};

export default {
  getUrlParam,
  transformKey,
  forecastFilterForLotteryType,
  getForecastText,
  getForecastNumberJudge
};
