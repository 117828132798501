import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import index from '../views/index/index.vue';
import { wx, util } from '@/common/utils';

Vue.use(VueRouter);

const routes = [
  // 底部导航页面相关
  {
    path: '/',
    component: index,
    children: [
      {
        path: '/',
        name: 'home',
        component: () =>
          import(
            /* webpackChunkName: "index" */ '../views/index/home/home.vue'
          ),
        meta: {
          title: '首页',
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: 'test',
        name: 'test',
        component: () =>
          import(/* webpackChunkName: "index" */ '../views/index/test.vue'),
        meta: {
          title: '测试'
        }
      },
      {
        path: 'masterList',
        name: 'masterList',
        component: () =>
          import(
            /* webpackChunkName: "index" */ '../views/index/masterList/masterList.vue'
          ),
        meta: {
          title: '大师榜',
          keepAlive: true,
          isBack: false,
          scollTopPosition: 0
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () =>
          import(
            /* webpackChunkName: "index" */ '../views/index/user/user.vue'
          ),
        meta: {
          title: '个人中心',
          keepAlive: true,
          isBack: false
        }
      }
    ]
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      needAuth: true
    }
  },
  // 用户相关
  {
    path: '/user',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/user/index.vue'),
    children: [
      // {
      //   path: 'login',
      //   name: 'login',
      //   component: () =>
      //     import(/* webpackChunkName: "user" */ '../views/user/login.vue'),
      //   meta: {
      //     title: '登录'
      //   }
      // },
      {
        path: 'register',
        name: 'register',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/register/register.vue'
          ),
        meta: {
          title: '生彩有道',
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: 'orderList',
        name: 'orderList',
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/user/orderList.vue'),
        meta: {
          title: '我的锦囊',
          keepAlive: true,
          isBack: false
        }
      },
      {
        path: 'orderDetail',
        name: 'orderDetail',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/orderDetail.vue'
          ),
        meta: {
          title: '订单详情'
        }
      },
      {
        path: 'userEdit',
        name: 'userEdit',
        component: () =>
          import(/* webpackChunkName: "user" */ '../views/user/userEdit.vue'),
        meta: {
          title: '编辑资料'
        }
      },
      {
        path: 'serviceList',
        name: 'serviceList',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/serviceList.vue'
          ),
        meta: {
          title: '会员卡活动'
        }
      },
      {
        path: 'serviceOrderList',
        name: 'serviceOrderList',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/serviceOrderList.vue'
          ),
        meta: {
          title: '我的服务'
        }
      },
      {
        path: 'serviceOrderListOverdue',
        name: 'serviceOrderListOverdue',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/serviceOrderList.vue'
          ),
        meta: {
          title: '我的过期服务'
        }
      },
      {
        path: 'serviceOrder',
        name: 'serviceOrder',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/serviceOrder.vue'
          ),
        meta: {
          title: '会员卡订单详情'
        }
      },
      {
        path: 'followerList',
        name: 'followerList',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/followerList.vue'
          ),
        meta: {
          title: '关注列表'
        }
      },
      {
        path: 'servicePage',
        name: 'servicePage',
        component: () =>
          import(
            /* webpackChunkName: "user" */ '../views/user/servicePage.vue'
          ),
        meta: {
          title: '智能会员'
        }
      }
    ]
  },
  // 分销相关
  {
    path: '/distribution',
    component: () =>
      import(
        /* webpackChunkName: "distribution" */ '../views/distribution/index.vue'
      ),
    children: [
      {
        path: 'about',
        name: 'about',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/about.vue'
          ),
        meta: {
          title: '分销商经营规范'
        }
      },
      {
        path: 'distributionData',
        name: 'distributionData',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/distributionData.vue'
          ),
        meta: {
          title: '分销数据'
        }
      },
      {
        path: 'promoteData',
        name: 'promoteData',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/promoteData.vue'
          ),
        meta: {
          title: '直推数据'
        }
      },
      {
        path: 'phoneRegister',
        name: 'phoneRegister',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/register.vue'
          ),
        meta: {
          title: '手机验证'
        }
      },
      {
        path: 'distributionShare',
        name: 'distributionSharePreview',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/distributionShare/distributionShare.vue'
          ),
        meta: {
          title: '生彩有道',
          notInitJssdkInBeforeEach: true
        }
      },
      {
        path: 'withdrawal',
        name: 'withdrawal',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/withdrawal.vue'
          ),
        meta: {
          title: '佣金提现'
        }
      },
      {
        path: 'withdrawalLog',
        name: 'withdrawalLog',
        component: () =>
          import(
            /* webpackChunkName: "distribution" */ '../views/distribution/withdrawalLog.vue'
          ),
        meta: {
          title: '提现记录'
        }
      }
    ]
  },
  // 彩票相关
  {
    path: '/lottery',
    component: () =>
      import(/* webpackChunkName: "lottery" */ '../views/lottery/index.vue'),
    children: [
      {
        path: 'share',
        name: 'lotteryShare',
        component: () =>
          import(
            /* webpackChunkName: "lottery" */ '../views/lottery/share.vue'
          ),
        meta: {
          title: '生彩有道',
          notInitJssdkInBeforeEach: true
        }
      },
      {
        path: 'history',
        name: 'history',
        component: () =>
          import(
            /* webpackChunkName: "lottery" */ '../views/lottery/history.vue'
          ),
        meta: {
          title: '往期开奖'
        }
      },
      {
        path: 'master',
        name: 'master',
        component: () =>
          import(
            /* webpackChunkName: "lottery" */ '../views/lottery/master/master.vue'
          ),
        meta: {
          title: '大师',
          notInitJssdkInBeforeEach: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  // 获取URL上的code
  const loginStatus = parseInt(sessionStorage.getItem('loginStatus') || 0);
  const code = util.getUrlParam('code');

  let queryStr = '';

  if (code && !loginStatus) {
    if (!sessionStorage.getItem('parentCodeOfParent')) {
      sessionStorage.setItem('parentCodeOfParent', to.query.parentCode || '');
    }
    store
      .dispatch('user/login', {
        code,
        toRouteName: to.name
      })
      .then(() => {
        next(to.fullPath);
      });
  } else if (to.name === 'login') {
    sessionStorage.setItem('loginStatus', 0);
    [, queryStr] = from.fullPath.split('?');
    wx.auth(
      `${from.path.slice(1)}${
        queryStr ? encodeURIComponent(`?${queryStr}`) : ''
      }`,
      false
    );
  } else if (!loginStatus) {
    [, queryStr] = to.fullPath.split('?');
    wx.auth(
      `${to.path.slice(1)}${
        queryStr ? encodeURIComponent(`?${queryStr}`) : ''
      }`,
      !to.meta.needAuth
    );
  } else {
    // 不是开发环境加载js-sdk
    if (
      process.env.NODE_ENV !== 'development' &&
      !to.meta.notInitJssdkInBeforeEach
    ) {
      wx.initJssdk();
    }
    next();
  }
});

export default router;
