import router from '@/router';
import { APPID, BASE_URL, WX_SHARE_URL } from './config';
import http from './http';
import vant from './vant';

const Jweixin = require('weixin-js-sdk');

const { Dialog } = vant;
const shareImgUrl = 'https://api.bornfortune.com/resource/image/logo.png';

// 判断是否在微信中
// const isWechat = () => {
//   let ua = window.navigator.userAgent.toLowerCase();
//   if (ua.match(/micromessenger/i) === 'micromessenger') {
//     // console.log('是微信客户端')
//     return true;
//   }
//   // console.log('不是微信客户端')
//   return false;
// };

const initJssdkConfig = sdkData => {
  Jweixin.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: sdkData.appId, // 必填，公众号的唯一标识
    timestamp: `${sdkData.timestamp}`, // 必填，生成签名的时间戳
    nonceStr: sdkData.nonceStr, // 必填，生成签名的随机串
    signature: sdkData.signature, // 必填，签名，见附录1
    jsApiList: [
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareAppMessage',
      'onMenuShareTimeline',
      'chooseWXPay',
      'closeWindow',
      'hideMenuItems'
    ]
  });
};

const initJssdkShare = () => {
  let parentCode = sessionStorage.getItem('parentCode') || '';
  let shareUrl = parentCode
    ? `${WX_SHARE_URL}?parentCode=${parentCode}`
    : `${WX_SHARE_URL}`;
  // let shareUrl =
  //   'http://test.bornfortune.com/index.html/#/?a1=R2NvZGUsOTEwNSxzaGVuZ2NhaXlvdWRhbw';
  // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
  // console.log('parentCode', parentCode);
  // console.log('shareUrl', shareUrl);
  Jweixin.updateAppMessageShareData({
    title: '生彩有道', // 分享标题
    desc: '加入生彩有道', // 分享描述
    link: shareUrl, // 分享链接
    imgUrl: shareImgUrl, // 分享图标
    success() {
      // 用户确认分享后执行的回调函数
    },
    cancel() {
      // 用户取消分享后执行的回调函数
    }
  });

  // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
  Jweixin.updateTimelineShareData({
    title: '生彩有道', // 分享标题
    link: shareUrl, // 分享链接
    imgUrl: shareImgUrl, // 分享图标
    success() {
      // 用户确认分享后执行的回调函数
    },
    cancel() {
      // 用户取消分享后执行的回调函数
    }
  });

  Jweixin.onMenuShareTimeline({
    title: '生彩有道', // 分享标题
    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: shareImgUrl, // 分享图标
    success() {
      // 用户点击了分享后执行的回调函数
    }
  });

  Jweixin.onMenuShareAppMessage({
    title: '生彩有道', // 分享标题
    desc: '加入生彩有道', // 分享描述
    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: shareImgUrl, // 分享图标
    type: '', // 分享类型,music、video或link，不填默认为link
    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    success() {
      // 用户点击了分享后执行的回调函数
    },
    cancel() {
      // 用户取消分享后执行的回调函数
    }
  });
};

// 获取微信公众号的配置并初始化SDK;
const initJssdk = callback => {
  // 开发环境不执行
  if (process.env.NODE_ENV === 'development') return;
  http(
    'jsSdk',
    { url: encodeURIComponent(window.location.href.split('#')[0]) },
    { loading: false, dialog: false }
  )
    .then(res => {
      initJssdkConfig(res);
      if (callback) return callback();
      Jweixin.ready(() => {
        // 隐藏分享到朋友圈和分享到QQ空间
        Jweixin.hideMenuItems({
          menuList: ['menuItem:share:timeline', 'menuItem:share:QZone']
        });
        initJssdkShare();
      });
    })
    .catch(error => {
      let errData = JSON.parse(error.message);
      Dialog.alert({
        message: `SDK加载失败，请联系管理员！\n错误信息：${errData.message}`
      });
    });
};

// 在需要自定义分享的页面中调用
const initShare = (data = {}) => {
  // 开发环境打印相关配置信息并直接放回成功
  if (process.env.NODE_ENV === 'development') {
    console.table(data);
    return Promise.resolve();
  }
  return new Promise(resolve => {
    let parentCode = sessionStorage.getItem('parentCode') || '';
    // 每次都需要重新初始化配置，才可以进行分享
    initJssdk(() => {
      Jweixin.ready(() => {
        let shareData = {
          title: data.title || '生彩有道',
          desc: data.desc || '加入生彩有道',
          link:
            data.link ||
            (parentCode
              ? `${WX_SHARE_URL}?parentCode=${parentCode}`
              : `${WX_SHARE_URL}`),
          imgUrl: data.img || shareImgUrl,
          success() {
            // 分享后的一些操作,比如分享统计等等
          },
          cancel() {}
        };
        // 隐藏分享到朋友圈和分享到QQ空间
        Jweixin.hideMenuItems({
          menuList: ['menuItem:share:timeline', 'menuItem:share:QZone']
        });
        // 分享给朋友接口
        Jweixin.onMenuShareAppMessage(shareData);
        // 分享到朋友圈接口
        Jweixin.onMenuShareTimeline(shareData);
        // 分享给朋友接口
        Jweixin.updateAppMessageShareData(shareData);
        // 分享到朋友圈接口
        Jweixin.updateTimelineShareData(shareData);
        resolve();
      });
    });
  });
};

const auth = (backUrl = '', isBash = true) => {
  sessionStorage.setItem(
    'loginType',
    isBash ? 'snsapi_base' : 'snsapi_userinfo'
  );
  // 开发环境跳过微信认证并设置token和登录完成状态，然后直接进入首页
  if (process.env.NODE_ENV === 'development') {
    localStorage.setItem('token', 'gold');
    sessionStorage.setItem('loginStatus', 2);
    return router.push(backUrl).catch(err => err);
  }
  let scope = isBash ? 'snsapi_base' : 'snsapi_userinfo';
  let wxUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?APPID=${APPID}&redirect_uri=${BASE_URL}${
    backUrl || ''
  }&response_type=code&scope=${scope}&state=123#wechat_redirect`;
  window.location.href = wxUrl;
};

const pay = config => {
  return new Promise((resolve, reject) => {
    let data = {
      timestamp: config.timeStamp,
      nonceStr: config.nonceStr,
      package: config.package,
      signType: config.signType,
      paySign: config.paySign
    };
    Jweixin.chooseWXPay({
      ...data,
      success: res => {
        resolve(res);
      },
      cancel: err => {
        reject(err);
      }
    });
  });
};

// 隐藏所有分享类的菜单项
const hideMenuItemsAboutShare = () => {
  initJssdk(() => {
    Jweixin.ready(() => {
      // 隐藏分享到朋友圈和分享到QQ空间
      Jweixin.hideMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:favorite',
          'menuItem:share:facebook',
          'menuItem:share:QZone'
        ]
      });
    });
  });
};

export default { pay, initJssdk, initShare, auth, hideMenuItemsAboutShare };
